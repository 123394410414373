import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .scroll {
    overflow: auto;
    white-space: nowrap;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media ${device.tablet} {
    white-space: normal;
  }

    div {
      display: inline-flex;
      box-shadow: rgba(0, 0, 0, 0.07) 0 0 5px 3px;
      border-radius: 8px;
      width: 150px;
      height: 82px;
    }
  }
`

export const CobrancaImages = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  opacity: 1;
  transition: opacity 500ms;
`
export const CobrancaImagesWrapper = styled.div`
  padding: 12px 6px;
`
