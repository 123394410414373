import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import HeroA from './sections/hero-a/_index'
import AproveiteAsVantagens from './sections/aproveite-vantagens/_index'
import FacaSuaNegociacao from './sections/faca-sua-negociacao/_index'
import AproveiteCondicoes from './sections/aproveite-condicoes/_index'
import NossosParceiros from './sections/nossos-parceiros/_index'
import Faq from './sections/faq/_index'

import QRCodeCondicoes from './assets/images/negociacao-amigavel-qr-code.png'
import QRCodeNegociacao from './assets/images/negociar-no-super-app-qr-code.png'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const WIDTH_MD = 768

const Negocie = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ openModalCondicoes, setOpenModalCondicoes ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()

  const domReady = useDomReady()
  const width = useWidth(300)

  const oneLinkNegociacao = 'https://inter-co.onelink.me/Qyu7/negociarnosuperapp'
  const oneLinkCondicoes = 'https://inter-co.onelink.me/Qyu7/lpnegociacaoamigavel'

  const negociacaoModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-20 lh-25 fs-md-24 lh-md-30">Aponte a câmera do seu celular para o QRCode para acessar o <span class="text-orange--extra">Super App</span> no seu dispositivo.</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o nosso app.'
        qrCode={QRCodeNegociacao}
      />
    </Modal>
  )

  const condicoesModal = domReady && (
    <Modal isModalOpen={openModalCondicoes} setIsModalOpen={setOpenModalCondicoes} locationToRender={document.body}>
      <QrCodeModal
        isModal={openModalCondicoes}
        setIsModal={setOpenModalCondicoes}
        title='<span class="d-block fs-20 lh-25 fs-md-24 lh-md-30">Aponte a câmera do seu celular para o QRCode para acessar o <span class="text-orange--extra">Super App</span> no seu dispositivo.</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o nosso app.'
        qrCode={QRCodeCondicoes}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Wrapper>
      {negociacaoModal}
      {condicoesModal}
      <Layout pageContext={pageContext}>
        <HeroA sendDatalayerEvent={sendDatalayerEvent} />
        <AproveiteAsVantagens />
        <FacaSuaNegociacao
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLinkNegociacao}
        />
        <AproveiteCondicoes
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setOpenModalCondicoes}
          isMobile={isMobile}
          oneLink={oneLinkCondicoes}
        />
        <NossosParceiros sendDatalayerEvent={sendDatalayerEvent} />
        <Faq faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}
export default Negocie
