import React from 'react'

import TabDropDown from 'src/components/TabDropDown'

import usePageQueryPartners from '../../../../assets/images/shared/parceiros/query'
import TabDropDownPartnersJSON from '../../../../shared/TabDropDownPartners.json'

import * as T from './_types'

import { CobrancaImages, CobrancaImagesWrapper, Section } from './style'
import Img from 'gatsby-image'
import grb from 'src/assets/images/shared/parceiros/logomarca-grb.png'

export const cobrancaParnersLogos: T.cobrancaParnersLogosProps = {
  acerto: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-acerto/image.webp',
  concentrix: 'https://central-imagens.bancointer.com.br/images-without-small-versions/concentrix/image.webp',
  grb: grb,
  renac: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-renac/image.webp',
  redeBrasil: 'https://central-imagens.bancointer.com.br/images-without-small-versions/rede-brasil/image.webp',
  paschoalotto: 'https://central-imagens.bancointer.com.br/images-without-small-versions/paschoalotto/image.webp',
  lott: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-lottadvocacia/image.webp',
  fiducial: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-fiducial/image.webp',
  exsen: 'https://central-imagens.bancointer.com.br/images-without-small-versions/exsen/image.webp',
  bellinati: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-bellinatiperez/image.webp',
  cmmm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cmmm/image.webp',
  cardoso: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-cardoso-correa/image.webp',
  maneira: 'https://central-imagens.bancointer.com.br/images-without-small-versions/logo-parceiros-maneira-advogados/image.webp',
  queirozCavalcanti: 'https://central-imagens.bancointer.com.br/images-without-small-versions/queiroz-cavalcanti/image.webp',
  novaQuest: 'https://central-imagens.bancointer.com.br/images-without-small-versions/nova-quest/image.webp',
}

const NossosParceiros = ({ sendDatalayerEvent }: T.INossosParceirosProps) => {
  const dataPartners = usePageQueryPartners()

  const allTabs = TabDropDownPartnersJSON.map((item: T.ItemProps) => item.tabName)
  return (
    <Section className='py-5'>
      <div className='container px-lg-0'>
        <h2 className='fs-24 fs-md-24 fs-lg-32 fs-xl-40 lh-30 lh-md-30 lh-lg-40 lh-xl-50 text-md-center text-grayscale--500'>
          <span className='d-xl-block'>Conte com nossos parceiros para negociar</span> seus débitos!
        </h2>
        <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 mt-md-4 mt-lg-5 text-grayscale--500 text-md-center'>
          <span className='d-xl-block'>Recebeu o contato de uma das empresas abaixo? Pode confiar! Você pode ser redirecionado para o site de</span> nossos parceiros oficiais, conforme a {' '}
          <a
            href='https://inter.co/politica-de-privacidade/'
            target='_blank'
            rel='noreferrer'
            title='Política de Privacidade'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_05',
                element_action: 'click button',
                element_name: 'Política de Privacidade do Inter',
                redirect_url: 'https://inter.co/politica-de-privacidade/',
                section_name: 'Conte com nossos parceiros para negociar seus débitos!',
              })
            }}
          >
            <u>Política de Privacidade do Inter</u>
          </a>.
        </p>
        <TabDropDown items={allTabs} className='mt-4 mt-xl-5'>
          {TabDropDownPartnersJSON.map((item: T.ItemProps) => {
            if (item.tabName === 'Cobrança') {
              return (
                <div key={item.tabName} className='scroll mt-3 p-2'>
                  {item.children.map((row: T.ChildrenProps) => (
                    <>
                      {row.image.map((image: string) => (
                        <CobrancaImagesWrapper key={row.title}>
                          <CobrancaImages src={cobrancaParnersLogos[image]} />
                        </CobrancaImagesWrapper>
                    ))}
                    </>
                  ))}
                </div>
            )
            } else {
              return (
                <div key={item.tabName}>
                  {item.children.map((row: T.ChildrenProps) => (
                    <>
                      <span className='mt-2 mt-lg-5'>{row.title}</span>
                      <div className='scroll mt-3 p-2'>
                        {
                          row.image.map((image: string) => (
                            <Img key={image} fluid={dataPartners[image].fluid} />
                          ))
                        }
                      </div>
                    </>
                    ))}
                </div>
              )
            }
          })}
        </TabDropDown>
      </div>
    </Section>
 )
}

export default NossosParceiros
